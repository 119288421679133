import { useContext, useEffect } from 'react'
import { ThemeContext } from './contexts/theme'
import Header from './components/Header/Header'
import About from './components/About/About'
import Projects from './components/Projects/Projects'
import Skills from './components/Skills/Skills'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import './App.css'
// import 'animate.css';

import ReactGA from 'react-ga'

ReactGA.initialize("UA-221599791-1")


ReactGA.pageview(window.location.pathname + window.location.search);


const App = () => {
  const [{ themeName }] = useContext(ThemeContext);

  // useEffect(() => {
  //   ReactGA.initialize('G-642ZN94BB4');
  //   ReactGA.pageview(window.location.pathname + window.location.search)
  //   },[]);

  return (
    <div id='top' className={`${themeName} app`}>
      <Header />

      <main>
        <About />
        <Projects />
        <Skills />
        <Contact />
      </main>

      <ScrollToTop />
      <Footer />
    </div>
  )
}

export default App
