import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { about } from '../../portfolio'
import './About.css'

const About = () => {
  const { name, role, education, description, resume, social, profileImage } = about

  return (
    <div className='about center'>
      {/* {profileImage && <img className="profile__image" alt="Abbey Albright" src={process.env.PUBLIC_URL + profileImage}  />} */}
      {name && (
        // <div className='typewriter'>
          <h1>
            Hi, I&apos;m <span className='about__name'>{name}.</span>
          </h1>
        // </div>
      )}

      {role && <h2 className='about__role'>A {role}.</h2>}

      <p className='about__edu'>{education && education}</p>

      <p className='about__desc'>{description && description}</p>

      <div className='about__contact center'>
        {resume && (
          <a href={resume}>
            <span type='button' className='btn btn--outline'>
              Resume
            </span>
          </a>
        )}

        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon icon-large'
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon icon-large'
              >
                <LinkedInIcon />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default About
