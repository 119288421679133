
const header = {
    // all the properties are optional - can be left empty or deleted
    homepage: '',
    title: '',
  }
  
  const about = {
    // all the properties are optional - can be left empty or deleted
    profileImage: '/abbeybercher.jpeg',
    name: 'Abbey Albright',
    role: 'Software Engineer',
    // role: ['Software Engineer', 'Full Stack Developer', 'Perpetual Student'],
    education: "Bachelor's of Science & Flatiron School Alumni",
    description:
      "I graduated from NC State in 2020. Not loving what I studied, and struggling to find a job mid pandemic, I started learning to code in my free time. I started freelance web development in 2021 while working full time in lab animal husbandry. In November 2021 I left my job to learn software engineering full time at Flatiron School.",
    resume: '/AbbeyBercherResume.pdf',
    social: {
      linkedin: 'https://www.linkedin.com/in/abbeybercher/',
      github: 'https://github.com/albercher',
    },
  }
  
  const projects = [
    // projects can be added an removed
    // if there are no projects, Projects section won't show up
    {
      name: "Buck-it, Let's Go",
      image: '/buckit.png',
      description:
        'A full-stack application that allows users to create a detailed travel-based bucket list visualized through a map',
      stack: ['Rails', 'React', 'MUI', 'Mapbox'],
      sourceCode: 'https://github.com/albercher/buck-it',
      livePreview: 'https://www.loom.com/share/e3dbbd43962f4c5484db03c65ce27308?sharedAppSource=personal_library',
    },
    {
      name: 'Wordle Infinite',
      image: '/wordle.png',
      description:
        'An enhanced clone of the popular game Wordle, with the added ability to login and play more than once a day',
      stack: ['Rails', 'React', 'CSS', 'Heroku'],
      sourceCode: 'https://github.com/albercher/wordle-clone',
      livePreview: 'https://wordle-infinite.herokuapp.com/',
    },
    {
      name: 'JobShare',
      image: '/jobboard.png',
      description:
        'A full-stack application where a user can search and sort through job listings, and create new ones',
      stack: ['React', 'JavaScript', 'Ruby', 'Sinatra', 'Active Record', 'MUI'],
      sourceCode: 'https://github.com/albercher/Job-Board-Frontend',
      livePreview: '',
    },
    {
      name: 'DataDash (Productivity App)',
      image: '/productivity.png',
      description:
        "A dashboard and suite of tools that provide an organized overview of a user's personal finances",
      stack: ['React', 'JavaScript', 'Recharts', 'MUI'],
      sourceCode: 'https://github.com/joshua-holmes/productivity-app',
      livePreview: 'https://www.loom.com/share/43c9df27209440a38e8d454309f87e78?sharedAppSource=personal_library',
    },
  ]
  
  const skills = [
    // skills can be added or removed
    // if there are no skills, Skills section won't show up
  
    'JavaScript',
    'React',
    'Ruby',
    'Rails',
    'HTML',
    'CSS',
    'JSON',
    'MUI',
    'Bootstrap',
    'Git',
    'GitHub'
  ]
  
  const contact = {
    // email is optional - if left empty Contact section won't show up
    email: 'abbeybercher@gmail.com',
    phone: '(704) 941-0181'
  }
  
  export { header, about, projects, skills, contact }
  