import './Footer.css'

const Footer = () => (
  <footer className='footer'>
 {/* empty */}
 Copyright © 2022 Abbey Albright
  </footer>
)

export default Footer
